import React, { Component } from 'react';

class PlayersOnline extends Component {
  state = {
    players: []
  }


  componentDidMount() {
    // const playersOnline = [];
    // if (this.props.players) {
    //   this.props.players.forEach((player) => {
    //     if (player.lastOnline) {
    //       let hours = moment().diff(moment(player.lastOnline), 'hours');
    //       if (hours < 2) {
    //         playersOnline.push(player);
    //       }
    //     }
    //   })

    //   this.setState({players: playersOnline}, () => {
    //     this.props.setOnlineStatus(playersOnline);
    //   })
    // }
  }


  render() {
    return (
      <>
        <img alt="online" height="20" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5d/Green_sphere.svg/1024px-Green_sphere.svg.png" /> 0 Players Online
      </>
    )
  }
}

export default PlayersOnline;