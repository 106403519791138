import { combineReducers } from 'redux';
import goodReducer from './goodReducer';
import shitReducer from './shitReducer';
import authReducer from './authReducer';
import postReducer from './postReducer';
import regionReducer from './regionReducer';
import lbReducer from './lbReducer';


export default combineReducers({
  good: goodReducer,
  shit: shitReducer,
  auth: authReducer,
  posts: postReducer,
  regions: regionReducer,
  lb: lbReducer
});
