import React, { Component } from 'react';
import axios from 'axios';



class PostsByCategory extends Component {
  constructor(props) {
    super(props)

    this.state = {
      categories: [],
      posts: [],
      cateLoad: true,
      postLoad: true
    }
  }



  componentDidMount() {
    axios.get('/api/category/').then((cats) => {
      this.setState({categories: cats.data, cateLoad: false})
    })

    axios.get('/api/posts').then((posts) => {
      this.setState({posts: posts.data, postLoad: false});
    })
  }


  render() {
    return (
      <div>

        {this.state.cateLoad || this.state.postLoad ? 'Loading' : ''}

        {this.state.categories.map((cat) => {
          return (
          <div>
            <strong>{cat.name}</strong>
            {/* {this.state.posts.map((post) => {
              if (post.category === cat.name) {
                return (
                  <a className="cate-link" href={`/archive/posts/${post.slug}`}>{post.title}</a>
                )
              }
            })} */}
            </div>
          )
        })}
      </div>
    )
  }
}

export default PostsByCategory;