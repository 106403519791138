import React, { Component } from 'react';

class ShowList extends Component {
  render() {
    return (
      <div className="player-list">
        <p>Ordered by when they were added</p>
        {this.props.data.map((data, index) => (
          <p className="player-name">{data.username}</p>
        ))}
      </div>
    )
  }
}

export default ShowList
