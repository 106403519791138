import React, {Component} from 'react';
import LBChart from './LBChart';
import moment from 'moment';
import { connect } from 'react-redux';
import { fetchRegions } from '../../../actions';



class BlocksPerDay extends Component {

  state = {
    loading: true,
    blocksByDate: undefined
  }

  componentDidMount() {
    
    let startingDate = moment('1576467694').format('MMM DD, YYYY');
    let endingDate = moment(new Date("2019-12-25T04:57:59.348Z")).format('MMM DD, YYYY');

    if (this.props.regions) {
      this.setState({regions: this.props.regions}, () => {
        this.formatDataByDate(startingDate, endingDate);
      })
    }
  }

  formatDataByDate = (startingDate, endingDate) => {
    const byDateObject = {};
    this.props.regions.forEach((region) => {
      if (region.currentStatus === 'DONE') {
        if (byDateObject[moment(region.history[0].timestamp).format('MMM DD, YYYY')]) {
          byDateObject[moment(region.history[0].timestamp).format('MMM DD, YYYY')] += region.blockCount;
        } else {
          byDateObject[moment(region.history[0].timestamp).format('MMM DD, YYYY')] = region.blockCount;
        }
      }
    })

    this.setState({blocksByDate: byDateObject, loading: false})

  }

  

  render() {
    return (
      <div>
        <div className="date-changer">
          {this.state.loading && <h4>Loading ...</h4>}
        </div>
        {/* we cant render the same lbchart twice, even if theres different data */}
        {this.state.blocksByDate ? <LBChart id="chart3" key="chart3" dates={this.state.blocksByDate} /> : <div style={{height: '100vh', paddingTop: '40px'}}>No Data this date</div>}

      </div>
    )
  }
}

function mapStateToProps({ regions }) {
  return {  regions };
}

export default connect(mapStateToProps, { fetchRegions })(BlocksPerDay);
