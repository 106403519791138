import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Markdown from 'markdown-to-jsx';


class ArchiveMain extends Component {
  state = {
    posts: [],
    loading: true
  }

  componentDidMount() {
    axios.get('/api/posts').then((posts) => {
      this.setState({posts: posts.data, loading: false});
    })
  }

  render() {
    return (
      <div className="col-reverse">
        {this.state.loading ? 'Loading' : ''}

        {this.state.posts.map((post) => {
          return (
          <div className="post-headers">
            <Link to={`/archive/posts/${post.slug}`}><h1>{post.title}</h1></Link>
            <Link to={`/archive/posts/${post.slug}`}><img alt={post.title} className="blog-thumb" src={post.thumbnail} /></Link>
            <Markdown>{post.content}</Markdown>
            <hr />
          </div>
          )
        }
        )
      }
      </div>
    )}
}

export default ArchiveMain;