import React, {Component} from 'react';
import axios from 'axios';
import LBChart from './LBChart';
import * as moment from 'moment';
import { connect } from 'react-redux';
import { fetchRegions } from '../../../actions';


class ByDayChart extends Component {

  state = {
    players: [],
    loading: true,
    regions: [],
    topPlayer: '',
    date: ''
  }

  componentDidMount() {
    let date = new Date("2019-12-25T04:57:59.348Z");
    date = moment(date).format('MMM DD, YYYY')
    if (this.props.regions) {
      this.setState({regions: this.props.regions}, () => {
        this.formatDataByDate(date);
      })

    } else {
      const url = localStorage.getItem('url');
      if (url) {
        this.fetchChartData(date, url)  
      }  
    }
  }

  fetchChartData = (date, url) => {
    axios.get(`/api/regions/final`).then((res) => {
      this.setState({regions: res.data}, () => {
        this.formatDataByDate(date);
      });
    })
  }

  changeDate = (direction) => {
    if (direction === 'back') {
      const newDate = moment(this.state.date).subtract(1, 'days');
      this.formatDataByDate(newDate)
      this.setState({date: newDate});
    } else {
      const newDate = moment(this.state.date).add(1, 'days');
      this.formatDataByDate(newDate);
      this.setState({date: newDate})
    }
  }

  formatDataByDate = (date) => {
    this.setState({players: []}, () => {
    const formattedPlayers = []
    // eslint-disable-next-line
    let players = this.state.regions.filter((region) => {
        return moment(date).format('MM-DD-YYYY') === moment(region.history[0].timestamp).format('MM-DD-YYYY')
    })
    players.forEach((player) => {
      let found = false;
      formattedPlayers.forEach((fPlayer) => {
        if (player.playerName === fPlayer.playerName) {
          found = true;
        }
      })

      if (!found) {
        formattedPlayers.push({playerName: player.playerName, blockCount: player.blockCount})

      } else {
        let index = formattedPlayers.findIndex((p) => p.playerName === player.playerName)
        formattedPlayers[index].blockCount += player.blockCount;

      }
    })
    // console.log('formatted players', formattedPlayers);

    const max = formattedPlayers.reduce((prev, current) => (prev.blockCount > current.blockCount) ? prev : current, 1)

    this.setState({players: formattedPlayers, date: date, loading: false, topPlayer: max.playerName});

    })
  }

  render() {
    return (
      <div>
        <div className="date-changer">
          <button className="date-btn" onClick={() => this.changeDate('back')}>⇦</button>
          {/* <div>{moment(this.state.date).format('MMM DD, YYYY')}</div> */}
          <button className="date-btn" onClick={() => this.changeDate('forward')}>⇨</button>
        </div>
        <div className="top-player">
          {this.state.loading && <h4>Loading ...</h4>}
        </div>
        {/* we cant render the same lbchart twice, even if theres different data */}
        {this.state.players.length && this.state.players ? <LBChart id="chart2" key="chart2" extraInfo={`Daily Winner: ${this.state.topPlayer}`} players={this.state.players} date={this.state.date} /> : <div style={{height: '100vh', paddingTop: '40px'}}>No Data this date</div>}

      </div>
    )
  }
}

function mapStateToProps({ regions }) {
  return {  regions };
}

export default connect(mapStateToProps, { fetchRegions })(ByDayChart);
