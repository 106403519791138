import React, { Component } from 'react';
import Header from '../Header';

class Landing extends Component {

  render() {
    return (
      <div>
        <Header />
        <div style={{backgroundImage: `linear-gradient(rgba(0,0,0,.4), rgba(255,255,255,.4)), url('https://i.redd.it/rfisr1w2xfu41.jpg')`}} className="dashboard">

          <h1 className="hero-title">The Royal Order of the SpawnMasons<br /> <small>No, you can't join.</small></h1>


          <p  className="photo-creds"><i>"The Country Club of 2b2t"</i></p>
        </div>
      </div>
    )
  }
}

export default Landing
