import React, { Component } from 'react';
import Header from '../../Header';
import axios from 'axios';
// import moment from 'moment'
import LbChart from './LBChart';
import ByDayChart from './ByDayChart';
import BlocksPerDay from './BlocksPerDay';
import PlayersOnline from './PlayersOnline';
import { Line,  } from 'rc-progress';
import './styles.scss';
import ByHourChart from './ByHourChart';
import moment from 'moment';
import { Link } from 'react-router-dom';

class Leaderboard extends Component {

  state = {
    players: {},
    arrOfPlayers: [],
    totalBlocks: 0,
    totalRegions: 0,
    totalAccs: 0,
    loading: true,
    playerOnline: [] // sent from child component
  }

  componentDidMount() {
    this.fetchPlayers();
  }

  fetchPlayers = () => {
    if (this.props.lb) {
      this.formatResponse(this.props.lb, false);
    } else {
      axios.get('/api/leaderboard/final').then((res) => {
        this.formatResponse(res, true)
      })  
    }

  }

  formatResponse = (res, apiCalled) => {
    let players = []

    if (apiCalled) {
      for (const name in res.data) {
        if (name) {
          const object = {
            name: name,
            sectionCount: res.data[name].sectionCount,
            blockCount: res.data[name].blockCount,
            altCount: res.data[name].altCount,
            lastOnline: res.data[name].lastOnline
          }

          if (object.lastOnline) {
            let hours = moment().diff(moment(object.lastOnline), 'hours');
            if (hours < 2) {
              object.online = true;
            }
          }
  

          players.push(object);
        }
      }  
    } else {
      for (const name in res) {
        if (name) {
          const object = {
            name: name,
            regionNumber: res[name].regionNumber,
            blockCount: res[name].blockCount,
            altCount: res[name].altCount,
            lastOnline: res[name].lastOnline
          }

          if (object.lastOnline) {
            let hours = moment().diff(moment(object.lastOnline), 'hours');
            if (hours < 2) {
              object.online = true;
            }
          }


          players.push(object);
        }
      }  
    }

    players = players.sort((a, b) => Number(b.blockCount) - Number(a.blockCount));

    this.getTotalBlocks(players)

    this.setState({
      players: players,
      loading: false
    })

  }

  getTotalBlocks = (players) => {
    let total = 0;
    let regions = 0;
    let accs = 0;
    players.forEach((player) => {
      total += player.blockCount;
      regions += player.regionNumber;
      accs += player.altCount + 1;
    })

    this.setState({totalBlocks: total, totalRegions: regions, totalAccs: accs})
  }

  render() {
    return (
      <div className="full">
        <Header navBack={"#c0392b"} />

        <div className="lb-header">
          <h2>Leaderboard</h2>
          <p>Includes charts, tables, and other miscellaneous data points.</p>
        </div>

        <table className="leaderboard-table">
          <thead className="table-head">
            <tr>
              <th>Rank</th>
              <th>Name</th>
              <th>Accounts</th>
              <th>Sections</th>
              <th>Blocks</th>
            </tr>
          </thead>
          <tbody>
            {this.state.players.length ? this.state.players.map((player, index) => (
              <tr key={player.name}>
                <th className="lb-col">{index + 1}.)</th>
                <th className="lb-col">{player.name} {player.online && <img alt="online" height="20" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5d/Green_sphere.svg/1024px-Green_sphere.svg.png" />}</th>
                <th className="lb-col">{player.altCount + 1}</th>
                <th className="lb-col">{player.regionNumber}</th>
                <th className="lb-col">{player.blockCount}</th>
              </tr>
            )) : <tr><th>Loading ...</th></tr>}
            <tr>
              <th className="lb-col"><b>TOTAL</b></th>
              <th className="lb-col">All SpawnMasons</th>
              <th className="lb-col">{this.state.totalAccs}</th>
              <th className="lb-col"><b>{this.state.totalRegions} / 1901</b></th>
              <th className="lb-col"><b>{this.state.totalBlocks} / 28154762</b></th>
            </tr>
          </tbody>
        </table>
        
        <div className="progress-bar-con">
          <Line percent={(this.state.totalRegions / 1901) * 100} strokeWidth="1" strokeColor="#27ae60" />
        </div>

        <div className="center">
          <div className="paper-header">
            <h3>
              {this.state.players.length ? <PlayersOnline players={this.state.players} setOnlineStatus={this.setOnlineStatus} /> : 'Fetching active players ...'}
            </h3>
          </div>
        </div>


        <div className="center">
          <div className="paper-header">
            <h3>Completed
              {this.state.totalBlocks ?
                " "+new Date((new Date("2019-12-25T04:57:59.348Z")-new Date("2019-12-17T02:11:26Z"))*((28154762 / this.state.totalBlocks) - 1) + new Date("2019-12-25T04:57:59.348Z")*1)
              : ' Calculating ...'}
            </h3>
          </div>
        </div>
    
        <div className="center">
          <div className="paper-header">
            <h3>
              Have averaged
              {this.state.totalBlocks ? " "+Math.round(100*this.state.totalBlocks/((new Date("2019-12-25T04:57:59.348Z")-new Date("2019-12-17T02:11:26Z"))/1000))/100 : ' Calculating ... '} blocks placed per second, continuously, since starting Dec. 16th, 2019.
            </h3>
          </div>
        </div>



        <div className="chart-container">
          {this.state.players.length && this.state.players && <LbChart id='chart1' key="chart1" players={this.state.players} />}
        </div>

        <div className="chart-container">
          <ByDayChart regions={this.props.regions} players={this.state.players} />
        </div>

        <div className="chart-container">
          <BlocksPerDay regions={this.props.regions} />
        </div>

        <div className="chart-container">
          <ByHourChart regions={this.props.regions} />
        </div>

        <div className="center">
          <div className="paper-header">
            <h3>
              <Link to="/spawn">Click to see visualized Logo Online - Includes section data.</Link>
            </h3>
          </div>
        </div>


        <div className="center">
          <div className="paper-header">
            <h3>
              <a target="_blank" rel="noopener noreferrer" href="https://eclipse.spawnmason.com">Click to see isomorphic map on the 2b2t world. </a>
            </h3>
          </div>
        </div>

      
      </div>
    )
  }
}

export default Leaderboard;
