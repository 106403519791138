import React, { Component } from 'react';
import axios from 'axios';

class BlogPosts extends Component {
  constructor(props) {
    super(props)


    this.state = {
      loading: true,
      posts: [],
      updated: false,
      showDelete: false,
      deleteItem: '',
      showEdit: false,
      editTitle: '',
      editSlug: '',
      editContent: '',
      editThumbnail: '',
      editId: ''
    }
  
    this.getPosts = this.getPosts.bind(this);
  }

  deleteItem = () => {
    axios.post(`/api/post/${this.state.deleteItem}/delete`).then(() => {
      this.getPosts();
      this.props.update();
      this.setState({showDelete: false, deleteItem: ''})
    })
  }

  editItem = () => {
    console.log(this.state.editId);
    axios.post(`/api/post/${this.state.editId}/edit`, {
      title: this.state.editTitle,
      thumbnail: this.state.editThumbnail,
      slug: this.state.editSlug,
      content: this.state.editContent
    }).then((post) => {
      this.getPosts();
      this.props.update();
      this.setState({showEdit: false})
    })
  }
    

  


  getPosts() {
    axios.get('/api/posts').then(post => {
      this.setState({posts: post.data, loading: false});
    })
  }

  componentDidMount() {
    this.getPosts();
  }

  render() {
    return (
      <div>
        <h1><strong>Blog Posts</strong></h1>
        <div className="col-reverse">
          {this.state.loading ? 'Loading' : ''}
          {this.state.posts.map((post) => {
            return (<p className="cate-p" key={post.title}>
              <span onClick={() => this.setState({showDelete: true, deleteItem: post.title})} className="delete">x</span>
              {post.title}
              <span onClick={() => this.setState({
                  showEdit: true,
                  editTitle: post.title,
                  editSlug: post.slug,
                  editThumbnail: post.thumbnail,
                  editContent: post.content,
                  editId: post._id
                })} className="edit-post right">edit</span>
              </p>)
          })}
        </div>

        {this.state.showDelete ? (
          <div className="confirm-delete">
            <p>Are you sure you want to delete {this.state.deleteItem}?</p>
            <button className="five-margin-right" onClick={() => this.deleteItem()}>Delete</button>
            <button onClick={() => {this.setState({deleteItem: '', showDelete: false})}}>Cancel</button>
          </div>
        ) : ''}

        {this.state.showEdit ? (
          <div className="confirm-delete">
            <p>Editing {this.state.editTitle}</p>
            <label>Title</label><br />
            <input name="title" onChange={(event) => this.setState({editTitle: event.target.value})} value={this.state.editTitle} />
            <br /><label>Slug/Url</label><br />
            <input name="slug" onChange={(event) => this.setState({editSlug: event.target.value})} value={this.state.editSlug} />
            <br /><label>Thumbnail</label><br />
            <input name="thumbnail" onChange={(event) => this.setState({editThumbnail: event.target.value})} value={this.state.editThumbnail} />
            <br /><label>Content</label><br />
            <textarea name="content" onChange={(event) => this.setState({editContent: event.target.value})} value={this.state.editContent} />
            <button className="five-margin-right" onClick={() => this.editItem()}>Edit</button>
            <button onClick={() => {this.setState({deleteItem: '', editTitle: '', editSlug: '', editThumbnail: '', editContent: '', showEdit: false})}}>Cancel</button>
          </div>
        ) : ''}

        
      </div>
    )
  }
}

export default BlogPosts;