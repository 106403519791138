import React, { Component } from 'react';
import Header from '../Header';
import './styles.scss';

class Alignment extends Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();

        let string = `Negative_Entropy,2,2,0,1,-2,0,0,2,-1,-1,-2,0,1,-2,0,1,1,0,2,0,1,0,-2,-1,1,-2,2,-2,-2,-2,2,-1,2,2,-2,2,-2,-2,,,,,,,,,,,,,,,,,,,,,,,,,,,
Clover,1,2,-1,0.5,-2,1,2,2,-1,0,0,-1,-1,-2,0,1,-1,-1,2,1,1.5,0.5,2,-1.5,0,-2,0,-2,-2,-2,-2,2,2,1.5,-2,0,-2,2,2,-1.5,0,2,2,2,-2,-2,-2,-2,-2,-2,-2,-2,-1,0,0,2,0,-2,2,2,-2,-1,1,2,0
Donald,1.75,1.75,-2,2,-2,1,2,0.5,-1,0,-1,-2,2,1,-1.5,2,,-1.5,2,,2,-0.5,2,0.5,,,,,,-2,,1.5,,2,,,-2,,2,,,,,,,0,2,-1,-2,2,2,,,,,,,,2,,2,,,,
Mothra,-1,1,1,-2,-2,2,-2,-1,-1,-2,-2,0,2,0,2,2,-2,1,1,2,2,0,-2,2,-1,2,,-2,1,1,2,0,2,-1,-2,1,-2,-2,2,1,-2,0,-2,2,-2,0,0,2,2,2,2,-2,0,-2,-1,2,2,2,2,2,2,-1,0,2,-2
Breithan,-1,2,0,2,-2,2,2,2,0,-1,-1,-2,-2,-1,0,2,,,,,,,,,,,,,,,,,,,,,-2,,,,,,,,,,,,,,,,,,,,,,,,,,,,
sakuya,-2,-1,0,0,2,2,-2,-2,-2,-1,-2,-2,2,2,2,2,-2,2,2,-2,-1,-1,-2,0,0,2,2,-2,2,-2,2,2,,-2,-2,2,-2,-2,2,-1.85,2,-2,-1,2,2,0,-2,-2,2,-2,2,0,-1,-1,0,0,0,-1,-1,2,2,2,-2,2,-2
Mr Pint,1.5,1.5,2,2,-2,0,2,2,-1,1,0,-2,-2,-1,0,2,,,,,,,,,,,,,,,,,,,,,-2,,,,,,,,,,,,,,,,,,,,,,,,,,,,
leijurv,1,2,2,2,0,-2,0,0.5,-2,-2,-1,2,2,0,2,2,2,2,2,2,1.5,2,-2,2,2,2,-2,-2,2,-2,-2,2,2,0,,,-2,-2,2,-2,2,2,,2,2,0,-2,,,,,1,,,,2,,-2,2,2,2,-2,-2,0,
BottlecapBrony,-1,2,1,2,-2,-2,-2,1.5,0,2,1,-2,0,-2,0,-2,2,0,2,-2,-2,2,0,-2,2,1,1,-2,-1,-1,0,2,2,0,0,0,-2,,,,,,,,,,,,,,-2,,,,,,,,,,,,,,
Hari,1,1,1,1,-2,2,2,2,0,2,1,-2,-1,-2,0,0,-2,2,2,2,1,0,-2,-2,,-2,0,0,0,1,2,2,2,1,2,2,-2,,,,,,,,,,,,,,,,,,,,,,,,,,,,
HermeticLock,1,1.9,1,2,-1.5,-2,0.5,1,-1,-2,2,-2,2,-2,2,2,2,2,1,-2,-0.5,2,1,2,2,2,2,2,1.5,0.5,-2,2,2,-1,1,-2,-1.7,-2,2,2,,0,-2,-2,2,-2,2,2,0,-2,2,0,1,2,-2,2,0,2,-1,2,2,2,-2,1,-2
l_amp,0,1,0,2,-2,2,0,1,-2,-1,2,-2,2,-2,2,2,-2,1,0,-2,-1,2,-2,-2,2,-2,-2,-2,0,0.5,1,2,2,0,-2,-2,-2,2,2,2,-2,0,-2,2,-2,2,-2,2,2,2,2,-2,2,2,0,2,0,-2,0,2,2,2,2,,2
BoomerangVillage,-2,1,-2,1,-2,-2,2,2,-1,-2,-2,-2,-2,-2,0,-2,0,2,2,2,2,0,-1,-2,,2,0,-2,-1,2,2,2,2,0,-2,-2,2,-2,,,,,,,,,,,,,,,,,,,,,,,,,,,
DieloTai,1.5,1.5,-1,2,-2,0,1,1,-1,-2,0,-2,-2,0,-1,2,,,,,,,,,,,,,,,,,,,,,-2,,,,,,,,,,,,,,,,,,,,,,,,,,,,
0x22,0,0,2,2,-2,-2,0,-1.5,-2,2,-2,1,2,2,2,2,2,0,1.5,2,-1,2,-2,0,2,0,2,-2,,-2,2,-1,1,-1,,,-2,,,,,,,,,,,,,,,,,,,,,,,,,,,,
Babbaj,0,0,2,2,-2,0,0,-2,-2,-2,2,-2,2,-2,2,-1,2,-1,2,2,-1,2,-2,0,,2,2,-1,0,-2,2,-1,1,-1,1,2,-2,,,,,,,,,,,,,,,,,,,,,,,,,,,,
Todarac,-2,0,-1,2,0,2,0,2,-1,-2,0,-2,-2,-1,2,2,1,2,2,-2,0,-3,-2,-2,2,2,2,-2,0,2,-1,0,1,1,0.25,2,-2,-2,2,1,2,2,-2,2,-2,2,-2,-2,2,-2,1,-2,-2,2,-2,2,1,-2,0,2,2,2,1,2,-2
NickHasCancer,0,-1,-1,-1,1,2,-1,2,-1,-2,1,-2,-2,-2,2,2,1,2,1,-2,0.5,-1,0.5,-2,-1,2,1,1,2,2,2,2,,,,,-2,,,,,,,,,,,,,,,,,,,,,,,,,,,,
PepsiBoy,-2,-1,2,2,-2,2,-1,-2,-2,-1,-2,-2,-1,1,2,-2,2,1,0,2,-2,2,-2,-2,-1,2,2,2,0,-2,2,-2,-2,-1,0.5,1,-2,2,1,2,-2,-2,-2,2,-2,0,-2,2,2,-1,2,-2,-2,-1,0,0,-2,-2,-2,-2,2,2,1,-2,-2
TheDark_Emperor,0,-2,2,-2,-2,0,-1,0,-1,0,0,-2,-2,2,-2,-2,2,2,0,2,-2,,,,,,,,,,,,,,2,,-2,,,,,,,,,,,,2,,,,,,,,,,,,,,,,
Wailn,1,2,1,2,-2,0,2,2,-1,-2,-1,-2,-2,-2,0,2,-1,0,0,2,2,0,-2,-2,2,-2,0,-2,-1,-1,2,-2,2,1,0,,-2,,,,,,,,,,,,,,,,,,,,,,,,,,,,
Biggus,0,0,0,2,-2,-2,0,0,-1,-1,-2,-2,-2,2,-2,-2,0,2,-1,2,2,-2,-2,-2,,-2,0,-2,-2,1.5,2,2,2,2,2,,-2,,,,,,,,,,,,,,,,,,,,,,,,,,,,
Nick's Tulpa,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,,-2,,,,,,,,,,,,,,,,,,,,,,,,,,,,
Jordanl666,2,1,0,2,0,-2,0,1,-2,-2,0,-2,-1,1,1,0,0,2,2,0,,2,-2,-2,,,,1,-1,2,2,0,1,2,1,2,-2,,,,,,,,,,,,,,,,,,,,,,,,,,,,
popbob,-1,0,2,1.995,-1.995,-2,-2,-2,-2,-2,-1.5,-2,2,1.75,0.005,1,2,-1.5,2,-2,2,2,0,0.005,-2,2,-2,2,-2,0.5,2,2,-2,-2,0,2,-2,2,2,2,2,0,-2,-2,-2,-2,-2,2,-1,2,1.5,-2,-2,-2,-2,-2,0,2,2,2,2,-2,-0.5,2,-2
jared2013,-2,-2,2,-2,2,2,-2,-2,-1,2,,,,,,2,2,,,,,,,,-2,,,,,,,,,,,,-2,,-2,,,,,,,,,,,,,,,,,,,,,,,,,,
DocSmurf,-1,1,2,-1,,-2,,,,,,,,,,2,,,,,,,,,,,,,,,,,,,,,-2,,,,,,,,,,,,,,,,,,,,,,,,,,,,
ufocrossing,1,-1,0,-2,,2,,,,,,0,,,,2,,,,,,,,,,,,,,,,,,,,,-2,,,,,,,,,,,,,,,,,,,,,,,,,,,,
Nordic93,-2,-1,2,-2,,,,,,-2,,,-2,,,2,,,,,,,,,,,,,,,,,,,,,-2,2,2,,,,,,,,,,,,,,,,,,,,,,,,,,
SalC1,2,-1,-1,,,,,,,,,,,,,2,,,,,,,,,,,,,,,,,,,,,-2,,,,,,,,,,,,,,,,,,,,,,,,,,,,
EntropyAnnDroid,1,0,1,,,,,,,-2,,,,,,2,,,,,,,,,,,,,,,,,,,,,-2,,,,,,,,,,,,,,,,,,,,,,,,,,,,
Hanscor,-1,0,0,,,,,,,,,,,,,2,,,,,,,,,,,,,,,,,,,,,-2,,,,,,,,,,,,,,,,,,,,,,,,,,,,
House,2,-2,2,,,,,,,,,,,,,2,,,,,,,,,,,,,,,,,,,,,-2,,,,,,,,,,,,,,,,,,,,,,,,,,,,
Terbin,0,2,-1,,,,,,,-2,,,,,,2,,,,,,,,,,,,,,,,,,,,,-2,,,,,,,,,,,,,,,,,,,,,,,,,,,,
Scribblefoxx,-2,2,0,,,,,,,,,,,,,2,,,,,,,,,,,,,,,,,,,,,-2,,,,,2,,,,,,,-2,,,,,,,-2,,,,,,,,,
Nekramite,1,1,1,2,,,1,1.5,-1,1,,-2,-2,-1,2,2,-1,0,2,,0.5,-0.5,-2,-2,,,-1,-2,-1.5,-2,2,-2,-1,1,0,2,-2,,,,,,,,,,,,,,,2,,,,,,,,,,,,,`;
        let playerTemp = {};
        string = string.split('\n');
        string.forEach((line) => {
            line = line.split(',');
            let name = line[0];
            playerTemp[name] = [];
            line.shift();
            playerTemp[name] = line.map((char) => parseFloat(char, 10));
        })

        this.state = {
            players: playerTemp,
            categories: [
                "Lawful +, Chaotic -","Good +, Evil -","Autistic +, Neurotypical -","Heterosexual +, Homosexual -","Trans +, Cis -","Pro-Tulpa +, Anti-Tulpa -","Wholesome +, Edgy -","Builder +, Griefer -","Vanilla +, Cheater -","Squeaker +, Boomer -","Thirsty +, Intellectual -","Dvorak +, Qwerty -","Coder +, Retard -","PvPer +, Pacifist -","Pepsi +, Coke -","Transphilic +, Transphobic -","Relevant +, Irrelevant -","Voice Chat +, Text -","Reddit +, Instagram -","Palestine +, Israel -","Liberal +, Conservative -","No Anime +, Anime -","Left handed +, Right handed -","Unix (GNU/Linux / BSD/Mac) +, Windows -","Dress left +, Dress right -","American +, Unlucky -","Team Vet +, Team Rusher -","Been in Voco +, Never in Voco -","Investor +, Wage Slave -","Drug User +, Boring -","T-shirts +, Collared Shirts -","Cats +, Dogs -","Prio +, Non Prio -","Sincere +, Ironic -","Wide +, Thin -","Wallet front pocket +, Wallet back pocket -","Boomerang + , Not Boomerang - ","Slave labor +, Bot labor -","Cute +, Uncute -","Top +, Bottom -","Cut +, Uncut -","Vines +, Ladder -","Tits +, Ass -","Breastfed +, Immunocompromised -","Born by C-section +, Turbochad -","Beer +, Wine -","Iphone +, Android -","Paint +, Photoshop -","Pro Bully +, Anti Bully -","Spaces +, Tabs -","Rational human being +, Brony -","Estonian heritage +, Subhuman -","Hot +, Cold -","Optimistic +, Pessimistic -","Bong +, Pipe -","At covid 2147 +, whiny bitch -","Watches SalC1 +, Watches Fit -","Pee standing up +, Pee sitting down -","Atheist +, Stupid -","Pro Trans Alaska Pipeline +, Anti Trans Alaska Pipeline -","North Hemisphere +, South Hemisphere -","Capitalism +, Communism -","Beef +, Chicken -","Top Ramen +, Hot Pockets -","Black +, White -"],
            categoryOne: 'Lawful +, Chaotic -',
            categoryTwo: 'Good +, Evil -'
        }
    }

    componentDidMount() {
        this.updateGraph();
    }
    
    changeSelectValue = (categoryNumber, categoryName) => {
        this.setState({
            [categoryNumber]: categoryName
        }, () => {
            this.updateGraph();
        })
    }

    updateGraph = () => {
        const playerPositions = [];
        let dpi = window.devicePixelRatio;
        const canvas = this.canvasRef.current;
        const ctx = canvas.getContext('2d');
        let c = document.getElementById('alignment');
        c.setAttribute('height', c.clientHeight * dpi);
        c.setAttribute('width', c.clientWidth * dpi);
        
        
        ctx.clearRect(0, 0, canvas.width, canvas.height);
     
        let xAxisLine = (c.clientWidth * dpi);    

        // y axis lines

        ctx.beginPath(); 
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 1;
        ctx.moveTo(0,0);
        ctx.lineTo(0, xAxisLine);
        ctx.stroke();

        ctx.beginPath(); 
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 1;
        ctx.moveTo(xAxisLine - 1.5, 0);
        ctx.lineTo(xAxisLine - 1.5, xAxisLine);
        ctx.stroke();

        ctx.beginPath(); 
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 1;
        ctx.moveTo((xAxisLine / 2), 0);
        ctx.lineTo((xAxisLine / 2), xAxisLine);
        ctx.stroke();

        ctx.beginPath(); 
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 1;
        ctx.moveTo(xAxisLine / 1.5, 0);
        ctx.lineTo(xAxisLine / 1.5, xAxisLine);
        ctx.stroke();

        ctx.beginPath(); 
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 1;
        ctx.moveTo(xAxisLine / 1.2, 0);
        ctx.lineTo(xAxisLine / 1.2, xAxisLine);
        ctx.stroke();


        ctx.beginPath(); 
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 1;
        ctx.moveTo(xAxisLine / 3, 0);
        ctx.lineTo(xAxisLine / 3, xAxisLine);
        ctx.stroke();

        ctx.beginPath(); 
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 1;
        ctx.moveTo(xAxisLine / 6, 0);
        ctx.lineTo(xAxisLine / 6, xAxisLine);
        ctx.stroke();




        // x axis lines
        ctx.beginPath(); 
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 1;
        ctx.moveTo(0, (c.clientHeight * dpi) - 1);
        ctx.lineTo(c.clientWidth * dpi, (c.clientHeight * dpi) - 1);
        ctx.stroke();

        ctx.beginPath(); 
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 1;
        ctx.moveTo(0, 0);
        ctx.lineTo(c.clientWidth * dpi, 0);
        ctx.stroke();

        // middle
        ctx.beginPath(); 
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 1;
        ctx.moveTo(0, c.clientHeight * dpi / 2);
        ctx.lineTo(c.clientWidth * dpi, c.clientHeight * dpi / 2);
        ctx.stroke();

        ctx.beginPath(); 
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 1;
        ctx.moveTo(0, c.clientHeight * dpi / 1.2);
        ctx.lineTo(c.clientWidth * dpi, c.clientHeight * dpi / 1.2);
        ctx.stroke();

        ctx.beginPath(); 
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 1;
        ctx.moveTo(0, c.clientHeight * dpi / 1.5);
        ctx.lineTo(c.clientWidth * dpi, c.clientHeight * dpi / 1.5);
        ctx.stroke();

        ctx.beginPath(); 
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 1;
        ctx.moveTo(0, c.clientHeight * dpi / 3);
        ctx.lineTo(c.clientWidth * dpi, c.clientHeight * dpi / 3);
        ctx.stroke();

        ctx.beginPath(); 
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 1;
        ctx.moveTo(0, canvas.height / 6);
        ctx.lineTo(c.clientWidth * dpi, canvas.height / 6);
        ctx.stroke();

        // add first one:
        this.state.categories.forEach((cat, catIndex) => {
            if (cat === this.state.categoryOne) {
                for (const player in this.state.players) {
                    if (player) {
                        let numberOne = this.state.players[player][catIndex];
                        if (numberOne || numberOne === 0) {
                            numberOne = this.getNumberValue(numberOne, false, c.clientHeight * dpi, c.clientWidth * dpi)
                            this.state.categories.forEach((catTwo, catIndexTwo) => {
                                if (catTwo === this.state.categoryTwo) {
                                    let numberTwo = this.state.players[player][catIndexTwo];
                                    if (numberTwo || numberTwo === 0) {
                                        numberTwo = this.getNumberValue(numberTwo, true, c.clientHeight * dpi, c.clientWidth * dpi)

                                        let playerInfo = {
                                            name: `${player}`,
                                            x: (c.clientWidth * dpi) - numberOne,
                                            z: (c.clientHeight * dpi) - numberTwo
                                        }
                                        let found = 0;
                                        playerPositions.forEach((info) => {
                                            if (info.x === (c.clientWidth * dpi) - numberOne && info.z === (c.clientHeight * dpi) - numberTwo) {
                                                found++
                                            } 
                                        })
                                        ctx.font = `${10 * dpi}px sans-serif`;

                                        if (found) {
                                            ctx.fillText(`${player}`, c.clientWidth * dpi - numberOne + 10, c.clientHeight * dpi - numberTwo - (13 * dpi) * found);    
                                        } else {
                                            ctx.fillText(`${player}`, c.clientWidth * dpi - numberOne + 10, c.clientHeight * dpi - numberTwo - 5);
                                            ctx.beginPath();
                                            ctx.arc(c.clientWidth * dpi - numberOne, c.clientHeight * dpi - numberTwo, 10, 0, 2 * Math.PI);
                                            ctx.fill();
 
                                        }

                                        playerPositions.push(playerInfo)


                                    }
                                }
                            })
                        }
                    }
                }
            }
        })      
    }

    getNumberValue = (num, secondNum, height, width) => {
        if (!secondNum) {
            return (num + 3) * (width / 6)
        } else {
            return (num + 3) * (height / 6)
        }
    }

    render() {
        console.log(this.state.categories)
        return (
            <div className="full alignment">
                <Header navBack={"#c0392b"} />

                <div className="lb-header">
                    <h2>SpawnMason Alighments</h2>
                    <p>Select two categories to compare them. +2 is the highest one can go, -2 is the lowest one can go.</p>
                </div>

                <div className="center">
                    <div className="paper-header flex-row cate-picker-con">
                        <div>
                            <h3>CATEGORY ONE (X-Axis)</h3>
                            <select onChange={(e) => this.changeSelectValue('categoryOne', e.target.value)}>
                                {this.state.categories.map((cat) => (
                                    <option selected={cat === this.state.CategoryOne} key={cat} value={cat} >{cat}</option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <h3>CATEGORY TWO (Y-Axis)</h3>
                            <select onChange={(e) => this.changeSelectValue('categoryTwo', e.target.value)}>
                                {this.state.categories.map((cat) => (
                                    <option selected={cat === this.state.categoryTwo} key={cat} value={cat} >{cat}</option>
                                ))}
                            </select>
                        </div>

                    </div>

                    {this.state.categoryOne === this.state.categoryTwo && <p>Please select two different categories.</p>}

                    <div style={{padding: '2rem'}} className="graph-container">

                        <h2><strong>{this.state.categoryOne}</strong> and <strong>{this.state.categoryTwo}</strong></h2>


                        <canvas
                            id='alignment'
                            height="600" width="600"
                            ref={this.canvasRef}
                        />

                        <div className="space-between-values">
                            <div>3</div>
                            <div>2</div>
                            <div>1</div>
                            <div>0</div>
                            <div>-1</div>
                            <div>-2</div>
                            <div>-3</div>
                        </div>

                        <div className="legend-label">
                            {this.state.categoryOne}
                        </div>

                        <div className="y-label legend-label">
                            {this.state.categoryTwo}
                        </div>

                        <div className="relative-y-values">
                            <div>3</div>
                            <div>2</div>
                            <div>1</div>
                            <div>0</div>
                            <div>-1</div>
                            <div>-2</div>
                            <div>-3</div>
                        </div>

                    </div>

                </div>

            </div>
        );
    }
}
 
export default Alignment;


