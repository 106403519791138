import React, { Component } from 'react';
import Header from './Header';
import Chart from "chart.js";

let myLodgeChart;

Chart.defaults.global.defaultFontFamily = "'PT Sans', sans-serif"
Chart.defaults.global.elements.point.backgroundColor = 'white';

class LodgeData extends Component {

  componentDidMount() {
    this.buildChart();
  }

  chartRef = React.createRef();


  buildChart() {
    // this.props.getUser();

    const myChartRef = this.chartRef.current.getContext("2d");

    if (typeof myLodgeChart !== "undefined") myLodgeChart.destroy();


    myLodgeChart = new Chart(myChartRef, {
      type: "scatter",
      responsive: true,
      data: {
        datasets: [{
            label: '2017',
            backgroundColor: '#27ae60',

            data: [
                {
                  x: -6140,
                  y: 2731
                },
                {
                    x: 1560,
                    y: -2315
                },{
                    x: 340,
                    y: 1980
                },{
                  x: -4840,
                  y: -4200
                },{
                x: 9420,
                y: -7260
                },{
                  x: -4290,
                  y: 5270
                },{
                  x: 37700,
                  y: -31460
                },{
                  x: 3070,
                  y: 5040
                },{
                  x: -14830,
                  y: -24760
                },{
                x: 10460,
                y: 10970
                },
                {
                x: 10700,
                y: 15070
                },{
                x: -7250,
                y: 5530
                },{
                x: -3250,
                y: -7720
                },{
                x: -26540,
                y: 18730
                },{
                x: 1720,
                y: -6650
                },{
                x: 5850,
                y: 11350
                },{
                x: 15400,
                y: 11415
                },{
                x: 7850,
                y: 9140
                },{
                x: 190,
                y: -10570
                },{
                x: 670,
                y: 6670
                },{
                x: 3320,
                y: 5840
                },{
                x: 14420,
                y: -1530
                }]},
                
                {label: '2018',
                backgroundColor: '#2c3e50',
                data: [
                {
                x: -15950,
                y: 5900
                },{
                x: 0,
                y: 13850
                },{
                x: -11930,
                y: -2800
                },{
                x: -14050,
                y: 9930
                },{
                x: 240,
                y: -149
                },{
                x: 12910,
                y: -37011
                },{
                x: -3550,
                y: 6700
                },{
                x: -7720,
                y: 6530
                },{
                x: 12110,
                y: -4400
                },{
                x: 2000,
                y: -3000
                },{
                x: 500,
                y: -2650
                },{
                x: 1000,
                y: 1000
                },{
                x: -13370,
                y: -1337
                },{
                x: 5724,
                y: 6704
                },{
                x: -3199,
                y: -2828
                },{
                x: -27415,
                y: 18844
                },{
                x: -7476,
                y: -7363
                },{
                x: 3370,
                y: -7363
                },{
                x: -9166,
                y: 6725
                },{
                x: 0,
                y: 7777
                },{
                x: -20400,
                y: 32550
                },{
                x: -24000,
                y: 34000
                },{
                x: 740,
                y: 2170
                },{
                x: -2000,
                y: -2000
                },{
                x: 12000,
                y: 18600
                }],
              	},
                
                {
                label: '2019',
                backgroundColor: '#f1c40f',
                data: [
                {
                x: -8050,
                y: -6250
                },{
                x: 8965,
                y: 8122
                },{
                x: 20200,
                y: -10800
                },{
                x: 3750,
                y: 7822
                },
                {
                  x: 7200,
                  y: -2900
                },
                {
                  x: 1750,
                  y: 530
                },
                {
                  x: -4360,
                  y: -3200
                },
                {
                  x: -2320,
                  y: 7850
                },
                {
                  x: 10500,
                  y: -16970
                },
                {
                  x: -3070,
                  y: -2700
                },
                {
                  x: -36900,
                  y: -13400
                },
                {
                  x: -37320,
                  y: -13075
                },
                {
                  x: -25750,
                  y: 4910
                },
                {
                  x: -36900,
                  y: -13600
                },
                {
                  x: -37094,
                  y: -13526
                },
                {
                  x: -36420,
                  y: -13420
                },
                {
                  x: 16820,
                  y: 12920
                },
                {
                  x: 53690,
                  y: 11160
                },
                {
                  x: -2900,
                  y: 7900
                },
                {
                  x: 953,
                  y: -6630
                },
                {
                  x: 22450,
                  y: 24300
                },
                /////////////// LAST USED
                {
                  x: 5000,
                  y: -7450
                },
                {
                  x: -7470,
                  y: -5170
                },
                {
                  x: -3300,
                  y: 6950
                },
                {
                  x: 3200,
                  y: -10500
                },
                {
                  x: -2020,
                  y: -1255
                },
                {
                  x: -4560,
                  y: -5340
                },
                {
                  x: 5000,
                  y: 5000
                },
                {
                  x: 850,
                  y: 2050
                },
                {
                  x: 2417,
                  y: 2417
                },
                {
                  x: -2700,
                  y: -10000
                },
                {
                  x: 128880,
                  y: 106830
                },
                {
                  x: 129060,
                  y: 106850
                },
                {
                  x: -3100,
                  y: -2147
                },
                {
                  x: 127846,
                  y: 106764
                },
                {
                  x: -3575,
                  y: -2350
                },
                {
                  x: 76666,
                  y: 12020
                },
                {
                  x: 14200,
                  y: -4180
                },
                {
                  x: 128760,
                  y: 106500
                },
                {
                  x: 127692,
                  y: 108525
                },
                {
                  x: 128660,
                  y: 106680
                },
                {
                  x: 128900,
                  y: 107200
                },
                {
                  x: 21830,
                  y: 16250
                },
                {
                  x: 128990,
                  y: 107300
                },
                {
                  x: 130650,
                  y: 107980
                },
                {
                  x: 4700,
                  y: -3574
                },
                {
                  x: 128108,
                  y: 106969
                },
                {
                  x: 128550,
                  y: 107200
                },
                {
                  x: 128960,
                  y: 106760
                },
                {
                  x: 109790,
                  y: 98360
                },
                {
                  x: -26600,
                  y: 12767
                },
                {
                  x: 128740,
                  y: 107000
                }
              ]},
              {
                label: '2020',
                backgroundColor: '#9b59b6',
                data: [
                {
                  x: 129080,
                  y: 107030
                },
                {
                  x: 900,
                  y: -5700
                },
                {
                  x: 129340,
                  y: 106350
                },
                {
                  x: -3650,
                  y: -3250
                },
                {
                  x: 3750,
                  y: 7822
                },
                {
                  x: 14000,
                  y: 4000
                },
                {
                  x: -5570,
                  y: 3820
                },
                {
                  x: -1140,
                  y: 670
                },
                {
                  x: 2023,
                  y: 0
                },
                {
                  x: 249400,
                  y: -371300
                },
                {
                  x: -20288,
                  y: -16356
                },
                {
                  x: -3200,
                  y: -7600
                },
                {
                  x: -5600,
                  y: -625
                },
                {
                  x: -6575,
                  y: -3000
                },
                {
                  x: 420,
                  y: 6969
                }
              ]

              }]
                },
                
      options: {
        //Customize chart options
        layout: {},
        // tooltips: DefaultLabels,
        legend: {
            display: true,
            position: 'bottom',
            labels: {
              usePointStyle: true,
            },
            usePointStyle: true,
          },
        scales: {
          xAxes: [{
            gridLines: {
              color: "rgba(0, 0, 0, 0)",
            }
          }],
        },
        tooltips: {
          callbacks: {
             label: function(tooltipItem, data) {
               if (tooltipItem.datasetIndex === 0) {
                const dataset = {}
                dataset.labels = [
                'March 18th 2017 | HermeticLock', 
                'Apr 8 2017 | herm jumbo',
                'Apr 29	2017 | end',
                'May 20 2017 | group',
                'May 27 2017 |	group',
                'Jun 3 2017 | entropy',
                'Jun 10 2017 | ain',
                'Jun 17 2017 | nick',
                'Jun 24 2017 |	henry',
                'Jul 1 2017 | alpha',
                'Jul 8 2017 | anar',
                'Jul 29 2017 | jack',
                'Aug 5 2017 | herm',
                'Sep 2 2017 | ain tanaxis',
                'Sep 9 2017 | frankfurt',
                'Sep 16	 2017 | slappn',
                'Sep 23 2017 | ain tavux',
                'Sep 30 2017 | sun',
                'Oct 7 2017 | mcneo entropy',
                'Nov 11 2017 | henry entropy',
                'Nov 18	 2017 | entropy',
                'Dec 2 2017 | herm',
                'Dec 9 2017 | jumbo',
                ]       
                var label = dataset.labels[tooltipItem.index];

                return tooltipItem.xLabel + ', ' + tooltipItem.yLabel + ' | ' + label;

               }

               if (tooltipItem.datasetIndex === 1) {
                const dataset = {}
                dataset.labels = [
                  'Jan 6 2018 | todarac',
                  'Jan 13 2018 | entropy',
                  'Jan 20 2018 | mcneo',
                  'Jan 27 2018 | dryg',
                  'Feb 3 2018 | entropy armorsmith',
                  'Feb 10 2018 | giganox',
                  'Feb 24 2018 | henry',
                  'Mar 3 2018 | entropy',
                  'Mar 17 2018 | mcneo',
                  'Mar 24 2018 | temp map',
                  'Apr 21 2018 | todarac entropy',
                  'Apr 29 2018 | entropy lamp',
                  'May 12 2018 | group',
                  'May 24 2018 | krobar',
                  'June 2 2018 | jumbo',
                  'July 8 2018 | Anar',
                  'July 21 2018 | entropy',
                  'Aug 11 2018 | rooster',
                  'Sep 1 2018 | lamp',
                  'Oct 7 2018 | lamp',
                  'Oct 27 2018 | jordan',
                  'Nov 3 2018 | nick jumbo',
                  'Dec 8 2018 | jumbo',
                  'Dec 15 2018 | nick jumbo',
                  'Dec 22	 2018 | nick jum entr tod',  
                ]       
                label = dataset.labels[tooltipItem.index];

                return tooltipItem.xLabel + ', ' + tooltipItem.yLabel + ' | ' + label;

              }

              if (tooltipItem.datasetIndex === 2) {
                const dataset = {}
                dataset.labels = [
                  'Jan 5 2019 | ain herm',
                  'Jan 13 2019 | nick mike',
                  'Jan 19 2019 | nick mike',
                  'Jan 26 2019 | boomerang',
                  'Feb 2 2019 | mike ufo'   ,
                  'Feb 9 2019 | end | ufo entr mike boom',
                  'Feb 16 2019 | ufo ter boom jor mike lamp',
                  'Feb 23 2019 | mike boomerang',
                  'Mar 2 2019 | frankfurt',
                  'Mar 9 2019 | boomerang',
                  'Mar 17 2019 | 	entropy ufo | Autistralia Lodge',
                  'Mar 23 2019 | terbin entropy | Autistralia',
                  'Mar 30 2019 | funncubes',
                  'Apr 6 2019 | group | Autistralia Lodge',
                  'Apr 14 2019 | dielo | Autistralia Lodge',
                  'Apr 20 2019 | boomerang | Ausistralia Lodge',
                  'Apr 27 2019 | breithan',
                  'May 4 2019 | anndroid',
                  'May 11 2019 | jordan',
                  'May 18 2019 | sal',
                  'May 25 2019 | entropy ufo',
                  'June 1 2019 | scribblefoxx negative_entropy | ice spike lodge',
                  'June 8 2019 | Terbin | Egg of Columbus',
                  'June 15 2019 | l_amp | Dark Oak Spleef Lodge',
                  'June 22 2019 | group | Price',
                  'June 29 2019 | Lavacast | group',
                  'July 7 2019 | Capital | DieloTai, group',
                  'July 13 2019 | Mooshroom | DieloTai',
                  'July 20 2019 | Area 51 | Scriblefoxx	Negative_Entropy	Breithan	ufocrossing	Jordanl666',
                  'July 27 2019 | Cornerstone | EntropyAnnDroid, group',
                  'August 3 2019 | Northern Air Temple | Negative_Entropy',
                  'August 10 2019 | Japanese | Giganox	IronException	Negative_Entropy',
                  'August 17 2019 | Courthouse, Masonic Trials | Scribblefoxx, Breithan',
                  'August 24 2019 | Cloud Club | Boomerange_Village, Masons, Gusts',
                  'August 31 2019 | Piano (y=143) | IronException	Scriblefoxx	BiggusDickus',
                  'September 7 2019 | Test server | Terbin HermeticLock',
                  'September 14 2019 | Cloudsdale PvP | BottleCapBrony',
                  'September 21 2019 | Boat Lodge | DieloTai',
                  'September 28 2019 | Dwarven Cave Hall (y=8) | TheFunnCubes',
                  'October 5 2019 | Bed Wars | Jordanl666',
                  'October 12 2019 | Smibadome | DieloTai	ufocrossing	Negative_Entropy	Todarac	Nekramite	Jordanl666',
                  'October 19 2019 | Hitchhikers guides Deep Thought | Scriblefoxx	Negative_Entropy',
                  'October 26 2019 | Harrowing village | BoomerangVillage',
                  'November 2 2019 | underground YES | Hanscor',
                  'November 11 2019 | Initiative testing | Jordanl666',
                  'November 16 2019 | 100th Lodge | The masons',
                  'November 23 2019 | Smibopoly (y=33) | Hari_Hari',
                  'November 30 2019 | Smib Church | FunnCubes',
                  'December 7 2019 | Smib Tower | ufocrossing',
                  'December 14 2019 | Temple of the cake | WarlordN1k	l_amp',
                  'December 21 2019 | Yacht (at Christmas Village) | BoomerangVillage',
                  'December 29 2019 | Smib christmas tree | Negative_Entropy'
                ]
                label = dataset.labels[tooltipItem.index];

                return tooltipItem.xLabel + ', ' + tooltipItem.yLabel + ' | ' + label;

              }

              if (tooltipItem.datasetIndex === 3) {
                const dataset = {}
                dataset.labels = [
                  'January 1 2020 | Little Smib | Scribblefoxx',
                  'Janurary 11 2020 | Upside Down Logo | ufocrossing',
                  'January 18 2020 | Fire nation attacks Smibville | Negative_Entropy	hanscor	Wailn',
                  'January 25 2020 | Project Shiny | leijurv	HermeticLock',
                  'February 1 2020 | Loo lodge too | BoomerangVillage',
                  'February 8 2020 | Mirror Lodge | Breithan',
                  'February 15 2020 | Nintendo Switch | Todarac',
                  'February 22 2020 | Corrupted Chunks | IronException	Negative_Entropy',
                  'February 29 2020 | Vine Cross | Scribblefoxx',
                  'March 7 2020 | Obtain rainbow dong | Bottlecapbrony',
                  'March 21 2020 | Quarantine | ufocrossing',
                  'March 29 2020 | 2017 Lush Cave but its 2020 | Nordic',
                  'April 4 2020 | Lavacast | Negative_Entropy	Ironexception',
                  'April 11 2020 | Easter Egg Basket | BoomerangVillage',
                  'April 18 2020 | Bigbong Lodge | Ufocrossing',
                  'April 25 2020 | Catflushed balloon | Breithan'
                ]
                label = dataset.labels[tooltipItem.index];

                return tooltipItem.xLabel + ', ' + tooltipItem.yLabel + ' | ' + label;

              }

             },
            
          }
 
      },
    }
    })


  }



  render() {
    return (
      <div className="full">
        <Header navBack={"#c0392b"} />

        <div style={{height: 'auto'}} className="section-header">
          <h2>Lodge Data</h2>
        </div>

        <div className="graph-container">

            <canvas
              id="myChart"
              ref={this.chartRef}
            />

        </div>


      </div>
    )
  }
}

export default LodgeData;
