import React, { Component } from 'react';
import Header from '../components/Header';


import Award from '../components/awards/Award';

class AwardsCon extends Component {

  render() {
    return (
      <div className="full">
        <Header navBack={"#c0392b"} />
          <div className="section-header">
            <h2>Player Awards</h2>
            <p>Occasionally we recognize members that go above and beyond in the 2b2t community.</p>
          </div>
          <div className="awards-page">
            <Award 
              name="Breithan"
              reason="Creating exceptional map art that's given him accolades in the community and on the subreddit."
              date="03/19/2019"
              skinUrl="https://minotar.net/avatar/breithan"
            />
            <Award 
              name="l_amp"
              reason="Recieved notoriety in the community for creating ban chunks at 0,0 nether."
              date="04/02/2019"
              skinUrl="https://minotar.net/avatar/l_amp"
            />
            <Award
              name="ufocrossing"
              reason="Spearheading the spawn logo project
              as well as simultaniously spearheading the largest mapart project."
              date="05/18/2019"
              skinUrl="https://minotar.net/avatar/ufocrossing"
            />
            <Award
              name="Terbin"
              reason="For his work in creating and hosting multiple servers for the Spawnmasons. This includes the Mason Archive Server, which contains many past WDLs and historic bases of the masons."
              date="06/08/2019"
              skinUrl="https://minotar.net/avatar/terbin"
            />
            <Award
              name="Scribblefoxx"
              reason=" For creating an elaborate multi-room underground courthouse lodge in order to put every mason on trial. Also for collaborating in creating the finest masonic map art to date specifically for the meeting."
              date="08/31/2019"
              skinUrl="https://minotar.net/avatar/Scribblefoxx"
            />

            <Award
              name="BoomerangVillage"
              reason="Creating the '2b2t Party Committee', a subsidiary group to the Spawnmasons, which has created themed Halloween & Christmas bases for 2 years now. Also for maintaining an active group of primarily Spawnmason builders & for letting us hold meetings before coords are released."
              date="12/28/2019"
              skinUrl="https://cdn.discordapp.com/attachments/587148918068871188/663554664775548929/boomeranghead.png"
            />

            <Award
              name="leijurv"
              reason="For his work on the Masonic Eclipse, the biggest spawn project in 2b2t's history with 28+ million blocks placed. A notable achievement of this project includes writing custom code into Baritone for Spawnmasons to use, which allowed this feat of engineering to be done in only 8 days."
              date="12/28/2019"
              skinUrl="https://minotar.net/avatar/leijurv"
            />



        </div>
      </div>
    )
  }
}

export default AwardsCon
