import React, { Component } from 'react';

class Award extends Component {
    render() {
        return (
            <div className="award">
                <img alt="medal" src="https://i.ibb.co/p4H1nMV/Medal-Of-Honor.png" />
                <div className="award-content">
                    <h1>{this.props.name} <span>{this.props.date}</span></h1>
                    <h1>Masonic Medal of Honor</h1>
                    <h2>Reason: <span className="smaller">{this.props.reason}</span></h2>
                    
                </div>
                <img className="mc-head" alt={this.props.name} src={this.props.skinUrl} />
            </div>
        )
    }
}

export default Award;