import React, { Component } from 'react';
import Header from '../Header';

import PostsByCategory from './PostsByCategory';
import ArchiveMain from './ArchiveMain';

import './styles.css';


class AwardsCon extends Component {

  render() {
    return (
      <div className="full archives-page">
        <Header navBack={"#c0392b"} />
        <div className="archive-container">
          <div className="posts-by-category">
            <PostsByCategory />
          </div>

          <div className="archive-main">
            <ArchiveMain />
          </div>
        </div>
      </div>
    )
  }
}

export default AwardsCon
