import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchUser } from '../actions';


class Header extends Component {
  componentDidMount() {
    this.props.fetchUser();
  }
  render() {
    return (
      <div className="nav" style={{background: this.props.navBack}}>
        <Link className="brand" to="/">SpawnMasons</Link>

        {/*<Link className="nav-link" to="/goodfellas">Goodfellas</Link>
        <Link className="nav-link" to="/shitlist">Shitlist</Link>*/}
        <Link className="nav-link" to="/awards">Awards</Link>
        <Link className="nav-link" to="/lodges">Lodges</Link>
        {/* <Link className="nav-link" to="/archives">Archive</Link> */}
        <Link className="nav-link" to="/leaderboard">Leaderboard</Link>
        <Link className="nav-link" to="/spawn">Logo</Link>
        {/* {this.props.auth.admin ? <Link className="nav-link" to="/admin">Admin</Link> : ''} */}
      </div>
    )
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps, { fetchUser })(Header);
