import React, { Component } from 'react';
import axios from 'axios';


class CreatePost extends Component {
  constructor(props) {
    super(props)

    this.state = {
      saved: false,
      title: '',
      categories: [],
      thumbnail: '',
      slug: '',
      category: '',
      content: '',
      updated: false,
      error: false
    }
  

    this.submitPost = this.submitPost.bind(this);
    this.grabCategories = this.grabCategories.bind(this);
  }

  componentDidMount() {
    this.grabCategories();
  }

  grabCategories() {
    axios.get('/api/category').then(categories => {
      this.setState({categories: categories.data})
    })
  }


  submitPost() {
    if (this.state.category) {
      axios.post('/api/post/new', this.state).then((post) => {
        console.log('saved')
        this.setState({saved: true, updated: true, error: false})
      }).then(() => {
        this.props.update();
      })
    } else {
      this.setState({error: true})
    }
    

  }


  render() {
    return (
      <div className="category-manager-post">
        <div className='flex-column-center'>
          <h1><strong>Create Post</strong></h1>
          <label>Title</label>
          <input name="title" placeholder="title" onChange={(event) => this.setState({title: event.target.value})}/>
          <label>Slug/Url eg: spawnmason.com/<b>url-of-post</b></label>
          <input name="slug" placeholder="the-url" onChange={(event) => this.setState({slug: event.target.value})}/>
          <label>Thumbnail</label>
          <input name="thumbnail" placeholder="img url" onChange={(event) => this.setState({thumbnail: event.target.value})}/>
          <label>Content</label>
          <textarea name="content" rows="50" placeholder="content" onChange={(event) => this.setState({content: event.target.value})}></textarea>
          <br />
          <button className="save-post-btn" onClick={this.submitPost}>Create new post</button>
          {this.state.saved ? 'saved' : ''}
          {this.state.error ? 'did you forget a category or something?' : ''}
        </div>

        <div className="category-list">
          <strong>Category</strong>
          {this.state.categories.map((cat, index) => {
            return  <p className={`category-option ${this.state.category === cat.name ? " selected " : ''}`} key={index} onClick={() => this.setState({category: cat.name})}>{cat.name}</p>
          })}
        </div>
      </div>
    )
  }
}

export default CreatePost;