import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchUser, fetchPosts } from '../../actions';

import Header from '../Header';
import BlogPosts from './BlogPosts';
import CreatePost from './CreatePost';
import ManageCategories from './ManageCategories';

import './styles.css';

class Admin extends Component {
  constructor(props) {
    super(props)

    this.updateStates = this.updateStates.bind(this);
  }

  componentDidMount() {
    this.props.fetchUser();
    this.props.fetchPosts();
  }

  updateStates() {
    this.forceUpdate();
  }

  render() {
    return (
      <div>
        <Header navBack={"#c0392b"} />
        {this.props.auth.admin ? (
          <div className="admin-page">
            <div className="previous-posts fifty-down-proper">
              <BlogPosts update={this.updateStates} getPosts={this.getPosts} posts={this.props.posts} />
            </div>

            <div className="admin-main fifty-down-proper">
              <div className="create-post">
                <CreatePost update={this.updateStates} />
                <p className="no-padding-margin">If this page doesn't update, refresh your page.</p>
                <p className="no-padding-margin"><a rel="noopener noreferrer" target="_blank" href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet">markdown docs</a></p>
              </div>
              <div className="manage-categories">
                <ManageCategories update={this.updateStates} />
              </div>
            </div>
          </div>
        ) : 'You are not an admin.'}
      </div>
    )
  }
}

function mapStateToProps({ auth, posts }) {
  return { auth, posts };
}

export default connect(mapStateToProps, { fetchUser, fetchPosts })(Admin);
