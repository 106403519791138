import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchGoodFella } from '../actions';

import Goodfellas from '../components/landing/Goodfellas';

class GoodFellasCon extends Component {

  componentDidMount() {
    this.props.fetchGoodFella();
  }

  render() {
    return (
      <div>
        <Goodfellas good={this.props.good} />
      </div>
    )
  }
}

function mapStateToProps({ good }) {
  return { good };
}

export default connect(mapStateToProps, { fetchGoodFella })(GoodFellasCon);
