import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchShitList } from '../actions';

import ShitList from '../components/landing/ShitList';

class ShitListCon extends Component {

  componentDidMount() {
    this.props.fetchShitList();
  }

  render() {
    return (
      <div>
        <ShitList shit={this.props.shit} />
      </div>
    )
  }
}

function mapStateToProps({ shit }) {
  return { shit };
}

export default connect(mapStateToProps, { fetchShitList })(ShitListCon);
