import React, { Component } from 'react';
import Header from '../Header';
import ShowList from './ShowList';

class Goodfellas extends Component {


  render() {
    return (
      <div className="full">
        <Header navBack={"#c0392b"} />

        <div className="section-header">
          <h2>GoodFellas</h2>
          <p>The following players are goodfellas.</p>
        </div>

        <ShowList data={this.props.good} />

      </div>
    )
  }
}

export default Goodfellas;
