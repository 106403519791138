import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchUser, fetchPosts, fetchRegions } from '../../actions';
import axios from 'axios';
import Header from '../Header';
import * as moment from 'moment'
import { Link } from 'react-router-dom';


import './styles.css';

class Spawn extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.regions && this.props.regions.length) {
      this.drawCanvas();
    } else {
      this.fetchRegions();
    }
    // if (this.props.regions && this.props.regions.length) {
    //   this.setState({loading: false}, () => {
    //     this.drawCanvas();
    //   })
    // } else {
    //   axios.get('/api/current_user').then((res) => {
    //     if (res.data) {
    //       this.generateLogo(res.data.logoPassword);
    //       this.setState({passwordRight: true})
    //     }
    //   });
    // }

    // const password = localStorage.getItem('password');

    // if (password != null) {
    //   this.state.password = password;
    //   this.sendPasswordStateAndUpdate();
    // }
  }

  state = {
    x: 0,
    z: 0,
    passwordRight: true, // this.props.regions && this.props.regions.length ? true : false,
    passwordIncorrect: false,
    password: '',
    regions: this.props.regions && this.props.regions.length ? this.props.regions : '',
    lowestX: 0,
    lowestZ: 0,
    currentRegion: {},
    loading: false
  };

  fetchRegions = (password) => {
    axios.get(`/api/regions/final`).then((res) => {
      this.setState({regions: res.data, loading: false}, () => {
        this.drawCanvas();
      });
    })
  };

  generateLogo = (password) => {
    if (!this.state.regions) {
      this.fetchRegions(password);
    } else {
      this.drawCanvas();
    }
  };

  drawCanvas = () => {
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');

    let squareSize = 10;
    let yAxisLine = (canvas.width / 2);
    let xAxisLine = (canvas.height / 2);

    const regions = this.state.regions;
    let lowestX = 0;
    let lowestZ = 0;
    regions.forEach((region) => {
      if (region.x < lowestX && region.z < lowestZ) {
        lowestX = region.x;
        lowestZ = region.z;
      }
    });

    regions.forEach((region) => {
      region.x += Math.abs(lowestX) * 5.62;
      region.z += Math.abs(lowestZ) * 1.85;
    });


	ctx.lineWidth = 1;
    regions.forEach((region, index) => {
      // 'DONE', 'CLAIMED', 'PLACING', 'QUEUEING
       if (region.currentStatus === 'PLACING') {
        ctx.strokeStyle = 'blue';
        ctx.strokeRect(region.x * squareSize, region.z * squareSize, squareSize, squareSize);
      } else if (region.currentStatus === 'CLAIMED') {
        ctx.strokeStyle = 'red';
        ctx.strokeRect(region.x * squareSize, region.z * squareSize, squareSize, squareSize);
      } else if (region.currentStatus === 'NOT_STARTED') {
        ctx.strokeStyle = 'lightgray';
        ctx.strokeRect(region.x * squareSize, region.z * squareSize, squareSize, squareSize);
      } else if (region.currentStatus === 'DONE') {
        ctx.fillStyle = 'black';
        ctx.fillRect(region.x * squareSize, region.z * squareSize, squareSize, squareSize);
        ctx.strokeStyle = 'lightgray';
        ctx.strokeRect(region.x * squareSize, region.z * squareSize, squareSize, squareSize);
      }
    });
    this.setRegionsState(regions, lowestX, lowestZ);

    // add axis lines
    ctx.beginPath(); 
    ctx.strokeStyle = 'green';
    ctx.lineWidth = 3;
    ctx.moveTo(yAxisLine ,0);
    ctx.lineTo(yAxisLine, canvas.height);
    ctx.stroke();

    ctx.beginPath(); 
    ctx.strokeStyle = 'red';
    ctx.lineWidth = 2;
    ctx.moveTo(0, xAxisLine);
    ctx.lineTo(canvas.width, xAxisLine);
    ctx.stroke();
    // console.timeEnd()

    let that = this;
    canvas.onmousemove = function(e) {
      // important: correct mouse position:
      const rect = this.getBoundingClientRect(),
      x = Math.floor((e.clientX - rect.left - canvas.width / 2) * 14.4) ,
      z = Math.floor((e.clientY - rect.top - canvas.height / 2) * 14.4),
      boxX = Math.floor((e.clientX - rect.left) - Math.abs(that.state.lowestX) / 5.6),
      boxZ = Math.floor(e.clientY - rect.top - Math.abs(that.state.lowestZ) / 1.9);

      that.setCoords(x, z);

      that.checkForMatch(boxX, boxZ, ctx, squareSize);
    };
  };

  setCoords = (x, z) => {
    this.setState({x: x, z: z})
  };

  checkForMatch = (x, z, ctx, squareSize) => {
    ctx.clearRect(0, 0, 1236, 1036);

    this.drawCanvas();
    
    let regionFound = false;
    this.state.regions.forEach((region, index) => {
      //let approxPosX = region.x * squareSize; // squaresize
      //let approxPosZ = region.z * squareSize;
      // if ((Math.abs(approxPosX - x) <= squareSize) && (Math.abs(approxPosZ - z) <= squareSize)) {
      if (Math.round(x / 10) - 1  === Math.floor(region.x) && Math.round(z / 10) - 1 === Math.floor(region.z)) {
        regionFound = true;
        this.setState({currentRegion: region});

        ctx.fillStyle = 'red';
        ctx.fillRect(region.x * squareSize, region.z * squareSize, squareSize, squareSize);
      }
    });

    if (!regionFound) {
      this.setState({currentRegion: {}});
    }
  };

  setRegionsState = (regions, lowestX, lowestZ) => {
    this.setState({regions: regions, lowestX: lowestX, lowestZ: lowestZ});
  };

  sendPasswordStateAndUpdate = () => {
    axios.post('/api/logo/password', {password: this.state.password}).then((res) => {
      if (res.data === 'NO.') {
        localStorage.removeItem('password');
        this.setState({passwordIncorrect: true});
      } else {
        localStorage.setItem('password', this.state.password);
        localStorage.setItem('url', res.data);
        this.generateLogo(res.data);
        this.setState({passwordRight: true});
      }
    });
  };

  render() {
    return (
      <div>
        <Header navBack={"#c0392b"} />

        {this.state.loading && (
          <div style={{position: 'absolute', top: '300px', left: '600px', backgroundColor: 'white'}}>
            <h1>LOADING ...</h1>
          </div>
        )}

        {!this.state.passwordRight && (
          <div style={{position: 'absolute', top: '300px', left: '600px', backgroundColor: 'white'}}>
            <h1>Whats the password?</h1>
            <input onChange={(e) => this.setState({password: e.target.value})} />
            <button onClick={() => this.sendPasswordStateAndUpdate()}>Submit</button>
            {this.state.passwordIncorrect && <h1>That's the wrong password.</h1>}
          </div>
        )}
        <div style={{paddingTop: '80px', paddingLeft: '20px'}}>

          <div className="hover-data">
            <p>X: {this.state.x} Z: {this.state.z}</p>
            <p><b>Status: </b> {this.state.currentRegion.currentStatus} </p>
            <p><b>
              Player: </b>  {this.state.currentRegion.playerName} 
              {this.state.currentRegion.altNumber ? ` - Alt: ${this.state.currentRegion.altNumber}` : ''}
            </p>
            <p><b>RegionId: </b> {this.state.currentRegion.x ? `${Math.floor(this.state.currentRegion.x)}, ${Math.floor(this.state.currentRegion.z)}` : ''} </p>
            <p><b>Timestamp: </b> {this.state.currentRegion.history && moment(this.state.currentRegion.history[0].timestamp).fromNow()}</p>

          </div>

          <canvas height="1036" width="1236" ref={this.canvasRef} />

          <p><Link to="/leaderboard">View All DATA and STATISTICS HERE!</Link></p>

          <p style={{fontWeight: 'bold', color: 'black'}}>Black: Done</p>
          <p style={{fontWeight: 'bold', color: 'blue'}}>Blue: In progress</p>
          <p style={{fontWeight: 'bold', color: 'red'}}>Red: Claimed</p>
          <p>One square = 144x144 blocks, or like 20k total.</p>
        </div>
      </div>
    )
  }
}

function mapStateToProps({ auth, posts, regions }) {
  return { auth, posts, regions };
}

export default connect(mapStateToProps, { fetchUser, fetchPosts, fetchRegions })(Spawn);
