import React, { Component } from 'react';
import Header from '../Header';
import PostsByCategory from './PostsByCategory';

import Markdown from 'markdown-to-jsx';

import axios from 'axios';



class Page extends Component {
  state = {
   title: '',
   content: 'Loading',
   thumbnail: ''
  }

  componentDidMount() {
    let theAptId = window.location.href.split('').reverse().join('');
    const aptI = theAptId.indexOf('/');

    theAptId = theAptId.substring(0, aptI);
    theAptId = theAptId.split('').reverse().join('');

    const axiosUrl = '/api/posts/' + theAptId;
    axios.get(axiosUrl).then((res) => {
      this.setState({ 
        content: res.data[0].content,
        title: res.data[0].title,
        thumbnail: res.data[0].thumbnail
      })
    })
  }

  render() {
    return (
      <div className="blog-page">
        <meta property="og:title" content={this.state.title} />
        <meta property="og:description" content={this.state.content} />
        <meta property="og:image" content={this.state.thumbnail} />

        <Header navBack={"#c0392b"} />
          <div className="posts-by-category">
            <PostsByCategory />
          </div>

          <div className="archive-main fifty-down">
            <div>
              <h1>{this.state.title}</h1>
              <img alt={this.state.title} className="blog-thumb" src={this.state.thumbnail} />
              <Markdown>{this.state.content}</Markdown>
            </div>
          </div>

      </div>
    )
  }
}

export default Page;