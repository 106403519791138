import React, { Component } from 'react';
import Header from '../Header';
import ShowList from './ShowList';

class ShitList extends Component {

  render() {
    return (
      <div className="full">
        <Header navBack={"#c0392b"} />

        <div className="section-header">
          <h2>Shitlist</h2>
          <p>The following players are shitters.</p>
        </div>


        <ShowList data={this.props.shit} />


      </div>
    )
  }
}

export default ShitList
