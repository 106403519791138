import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';

import './transitions.css';
import './styles.scss';

import Landing from './landing/Landing';
import GoodFellasCon from '../Containers/GoodFellasCon';
import ShitListCon from '../Containers/ShitListCon';
import LodgeData from './LodgeData';
import AwardsCon from '../Containers/AwardsCon';
import Archives from './archives/Archives';
import Page from './archives/Page';
import Admin from './admin/admin';
import Leaderboard from './landing/leaderboard/Leaderboard';

import Spawn from './spawn/Spawn';
import Alignment from './alignment/Alignment';

class App extends Component {


  componentDidMount() {
    this.props.fetchGoodFella();
    this.props.fetchShitList();
    this.props.fetchLB();
    this.props.fetchRegions().then((regions) => {
      this.setState({regions: regions})
    });
  }

  state = {
    regions: []
  }

  render() {



    return (
        <BrowserRouter>
            <div>
              <Route exact path="/" component={Landing} />
              <Route exact path="/goodfellas" component={GoodFellasCon} />
              <Route exact path="/shitlist" component={ShitListCon} />
              <Route exact path="/awards" component={AwardsCon} />
              <Route exact path="/lodges" component={LodgeData} />
              <Route exact path="/archives" component={Archives} />
              <Route path="/archive/posts" component={Page} />
              <Route exact path="/admin" component={Admin} />
              <Route exact path="/spawn" component={Spawn} />
              <Route
                path='/leaderboard'
                component={() => <Leaderboard regions={this.state.regions} lb={this.props.lb} />}
              />

              <Route exact path="/alignment" component={Alignment} />

            </div>
        </BrowserRouter>
    );
  };
};


function mapStateToProps({ good, shit, Regions, lb }) {
  return { good, shit, Regions, lb };
}

export default connect(mapStateToProps, actions)(App);


// export default connect(null, actions)(App);
