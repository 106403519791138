import axios from 'axios';
import { FETCH_GOODFELLAS, FETCH_SHITLIST, FETCH_USER, FETCH_POSTS, FETCH_REGIONS, FETCH_LB } from './types';

export const fetchUser = () => async dispatch => {
  const res = await axios.get('/api/current_user');

  dispatch({ type: FETCH_USER, payload: res.data });
};

export const fetchGoodFella = () => async dispatch => {
  const res = await axios.get('/api/goodfellas')

  dispatch({ type: FETCH_GOODFELLAS, payload: res.data });
};

export const fetchShitList = () => async dispatch => {
  const res = await axios.get('/api/shitlist')

  dispatch({ type: FETCH_SHITLIST, payload: res.data });
};

export const fetchPosts = () => async dispatch => {
  const res = await axios.get('/api/posts');

  dispatch({ type: FETCH_POSTS, payload: res.data });
};

export const fetchRegions = () => async dispatch => {
  // const password = localStorage.getItem('url');
  // if (password) {
    const res = await axios.get(`/api/regions/final`);

    dispatch({ type: FETCH_REGIONS, payload: res.data });  
  // }
};

export const fetchLB = () => async dispatch => {
  const res = await axios.get('/api/leaderboard/final');

  dispatch({ type: FETCH_LB, payload: res.data });  
}
