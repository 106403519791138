import React, { Component } from 'react';
import axios from 'axios';

class ManageCategories extends Component {
  constructor(props) {
    super(props)

    this.saveCategory = this.saveCategory.bind(this);
    this.getCategories = this.getCategories.bind(this);
  }

  state = {
    name: '',
    categories: [],
    saved: false,
    showDelete: false,
    deleteItem: ''
  }

  componentDidMount() {
    this.getCategories();
  }

  getCategories() {
    axios.get('/api/category').then(categories => {
      this.setState({categories: categories.data})
    })
  }

  saveCategory() {
    axios.post('/api/category/new', {name: this.state.name}).then((category) => {
      this.setState({saved: true});
    }).then(() => {
      this.getCategories();
      this.props.update();
    })
  }

  deleteItem = () => {
    axios.post(`/api/category/${this.state.deleteItem}/delete`).then(() => {
      this.getCategories();
      this.props.update();
      this.setState({showDelete: false, deleteItem: ''})
    })
  }

  render() {
    return (
      <div className="category-manager">
        <div className="category-list">
          {this.state.categories.map((cat) => {
            return <div className="cate-p" key={cat._id}><span onClick={() => this.setState({showDelete: true, deleteItem: cat.name})} className="delete">x</span><span className="">{cat.name}</span></div>
          })}
        </div>

        <div className="create-new-cat">
          <h3>Create Cateogry</h3>
          <input onChange={(event) => this.setState({name: event.target.value})} placeholder="category name" />
          <button onClick={this.saveCategory}>Save Category</button>
        </div>

        {this.state.showDelete ? (
          <div className="confirm-delete">
            <p>Are you sure you want to delete {this.state.deleteItem}?</p>
            <button className="five-margin-right" onClick={() => this.deleteItem()}>Delete</button>
            <button onClick={() => {this.setState({deleteItem: '', showDelete: false})}}>Cancel</button>
          </div>
        ) : ''}
      </div>
    )
  }
}

export default ManageCategories;