import React, { Component } from 'react';
import Chart from "chart.js";
import moment from 'moment';

let myLodgeChart = {}

Chart.defaults.global.defaultFontFamily = "'PT Sans', sans-serif"
Chart.defaults.global.elements.point.backgroundColor = 'white';


class LBChart extends Component {

  componentDidMount() {
    this.buildChart();
  }

  chartRef = React.createRef();


  buildChart() {
    // this.props.getUser();
    let datasets = [] 
    let labels = []

    if (!this.props.dates) {
      this.props.players.forEach((player) => {
        const playerObject = {};
        playerObject.label = player.name ? player.name : player.playerName;
        playerObject.backgroundColor = '#27ae60';
        playerObject.data = player.blocks ? player.blocks : player.blockCount;
  
        // datasets.push(playerObject);
        datasets.push(playerObject.data)
        labels.push(playerObject.label)
      })  
    } else {
      let that = this
      const arrayOfDates = [];
      for (const date in that.props.dates) {
        if (date) {
          arrayOfDates.push({
            date: date,
            blockCount: that.props.dates[date]
          })
        }
      }

      const orderedDates = arrayOfDates.sort(function(a,b){
        return new Date(a.date) - new Date(b.date);
      });

      orderedDates.forEach((node) => {
        labels.push(node.date);
        datasets.push(node.blockCount);
      })
      
    }

    // console.log(this.props.id, datasets)

    const myChartRef = this.chartRef.current.getContext("2d");

    // console.log('chartref', this.chartRef);

    if (typeof myLodgeChart[this.props.id] !== "undefined") myLodgeChart[this.props.id].destroy();


    myLodgeChart[this.props.id] = new Chart(myChartRef, {
      type: "bar",
      responsive: true,
      data: {
        //Bring in data
        labels: labels,
        datasets: [
          {
            label: 'Blocks Placed',
            backgroundColor: '#3498db',
            data: datasets
          }
        ]
      },
      options: {
        //Customize chart options
        layout: {},
        // tooltips: DefaultLabels,
        legend: {
            display: true,
            position: 'bottom',
            labels: {
              usePointStyle: true,
            },
            usePointStyle: true,
          },
        scales: {
          xAxes: [{
            gridLines: {
              color: "rgba(0, 0, 0, 0)",
            },
            ticks: {
              maxRotation: 90
            }
          }],
          yAxes: [{
            ticks: {
                beginAtZero: true,
            }
        }]
        },
      }
    })


  }



  render() {
    return (
      <div>

        <div className="chart-header">
          {this.props.date ? <h2>Daily Most Blocks Placed: {moment(this.props.date).format('MMM DD, YYYY')}</h2> : this.props.dates ? this.props.hour ? <h2>Total Blocks Placed Per Hour</h2> : <h2>Total Blocks Placed Per Day</h2> : <h2>All Time - Most Blocks Placed</h2>}
          {this.props.extraInfo ? <p>{this.props.extraInfo}</p> : ''}
        </div>

        <div style={{padding: '2rem'}} className="graph-container">

            <canvas
              id={this.props.chartId}
              ref={this.chartRef}
            />

        </div>


      </div>
    )
  }
}

export default LBChart;
